
import { Icon } from '@chakra-ui/react';
export const Helpful = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
<path d="M10.0617 4.21764C6.51358 5.11418 4 8.34496 4 11.9957C4 13.07 4.24247 14.2492 4.65466 15.1296C4.73548 15.3234 4.80823 15.4931 4.80823 15.5173C4.80823 15.5334 4.97795 15.8323 5.18809 16.1796C5.62453 16.9065 6.87728 18.2069 7.55619 18.6431C9.00291 19.5719 10.4011 20 11.9853 20C15.8163 20 19.0896 17.3588 19.8897 13.6192C20.0433 12.8923 20.0352 10.8973 19.8735 10.2673C19.8008 10.0007 19.7281 9.69381 19.7038 9.58073C19.6796 9.46766 19.5098 9.04766 19.3159 8.65188C18.2248 6.34995 16.3174 4.7911 13.8361 4.17725C12.8258 3.92687 11.1205 3.94302 10.0617 4.21764Z" fill="currentColor" fill-opacity="0.16"/>
<path d="M10.0228 4.21591C9.12533 4.44209 7.82366 5.06408 7.09602 5.61337C5.88329 6.53425 4.86459 7.94787 4.3795 9.37764C4.03185 10.4116 3.99951 10.6539 3.99951 12.0029C3.99951 13.3519 4.03185 13.5943 4.38759 14.6282C5.46288 17.8028 8.55939 20 11.9631 20C14.518 20 16.8707 18.8287 18.3744 16.8093C19.6357 15.121 20.2016 13.0611 19.9348 11.1144C19.7731 9.87039 19.6114 9.33726 19.1021 8.32753C18.2532 6.66349 16.6928 5.24179 14.9545 4.53902C13.5478 3.96549 11.5185 3.83625 10.0228 4.21591ZM13.2325 4.90252C15.6337 5.45182 17.3881 6.76043 18.3825 8.7395C18.9646 9.9027 19.1183 10.5893 19.1183 12.0029C19.1102 12.9884 19.0859 13.2873 18.9242 13.8608C18.2532 16.2196 16.5149 18.0452 14.1946 18.8287C11.7368 19.6446 9.00406 19.0387 7.09602 17.2616C6.07733 16.3165 5.38203 15.1614 4.99395 13.8043C4.84843 13.2792 4.81609 12.9804 4.82417 11.9626C4.82417 10.7509 4.88076 10.4116 5.21224 9.49881C5.54373 8.56986 6.31179 7.43896 7.11219 6.68772C7.88834 5.96072 9.22235 5.24987 10.2653 5.01561C10.5968 4.94291 10.9121 4.87021 10.9525 4.85406C11.1627 4.78943 12.8929 4.82174 13.2325 4.90252Z" fill="currentColor"/>
<path d="M8.59981 9.49074C8.05813 9.72499 7.92068 10.5651 8.35727 11.0013C9.19001 11.8333 10.4593 10.5893 9.6751 9.70076C9.48107 9.47458 8.89895 9.36149 8.59981 9.49074Z" fill="currentColor"/>
<path d="M14.5584 9.48266C14.2511 9.57959 14.0248 9.94309 14.0248 10.3631C14.0248 10.6782 14.0652 10.759 14.3239 11.0013C15.1809 11.7848 16.3694 10.6378 15.6256 9.74923C15.383 9.47458 14.9626 9.36149 14.5584 9.48266Z" fill="currentColor"/>
<path d="M9.0364 14.4344C8.86661 14.5313 8.84236 14.911 8.98789 15.0564C9.06065 15.1291 9.82872 15.1533 11.9308 15.1533C14.4128 15.1533 14.8009 15.1371 14.906 15.0241C15.0758 14.8544 15.0677 14.6444 14.8899 14.4828C14.7524 14.3617 14.4371 14.3455 11.955 14.3455C10.2168 14.3455 9.11725 14.3778 9.0364 14.4344Z" fill="currentColor"/>
</Icon>
  );
