export const localStorageKeys = {
  isOnboardingComplete: 'isOnboardingComplete',
  selectedTeamInMeshList: 'selectedTeamInMeshList',
  selectedTaskType: 'selectedTaskType',
  selectedTasksListViewType: 'selectedTasksListViewType',
  loginType: 'loginType', // how user logged in, to be used in onboarding to decide whether to show change password form or not
  selectedMeshListTab: 'selectedMeshListTab',
  teamPageGoalsFilters: 'teamPageGoalsFilters',
  teamPageGoalsTimeBounds: 'teamPageGoalsTimeBounds',
  teamPageTasksFilters: 'teamPageTasksFilters',
  tagPageGoalsFilters: 'tagPageGoalsFilters',
  tagPageTasksFilters: 'tagPageTasksFilters',
  teamPageGoalsStatsFilters: 'teamPageGoalsStatsFilters',
  teamPageTasksStatsFilters: 'teamPageTasksStatsFilters',
  teamPageGoalsViewType: 'teamPageGoalsViewType',
  teamPageTasksViewType: 'teamPageTasksViewType',
  teamPageObjective: 'teamPageObjective',
  analyticsGoalFilters: 'analyticsGoalFilters',
  analyticsOneOnOneFilters: 'analyticsOneOnOneFilters',
  analyticsFeedbackFilters: 'analyticsFeedbackFilters',
  analyticsLevelUpFilters: 'analyticsLevelUpFilters',
  analyticsEngagementFilters: 'analyticsEngagementFilters',
  analyticsGoalsCSVDownloads: 'analyticsGoalsCSVDownloads',
  analyticsEngagementCSVDownloads: 'analyticsEngagementCSVDownloads',
  analyticsOneOnOneCSVDownloads: 'analyticsOneOnOneCSVDownloads',
  analyticsFeedbackCSVDownloads: 'analyticsFeedbackCSVDownloads',
  reviewTeamStatusSelectedCycle: 'reviewTeamStatusSelectedCycle',
  analyticsGoalTableFilters: 'analyticsGoalTableFilters',
  analyticsOneOnOneTableFilters: 'analyticsOneOnOneTableFilters',
  analyticsEngagementTableFilters: 'analyticsEngagementTableFilters',
  analyticsFeedbackTableFilters: 'analyticsFeedbackTableFilters',
  analyticsLevelUpTableFilters: 'analyticsLevelUpTableFilters',
  adminEmployeesCSVDownloads: 'adminEmployeesCSVDownloads',
  rabbitGoalsInsightsTableRowsPerPage: 'rabbitGoalsInsightsTableRowsPerPage',
  rabbitGoalsAuditTableRowsPerPage: 'rabbitGoalsAuditTableRowsPerPage',
  rabbitGoalsListTableRowsPerPage: 'rabbitGoalsListTableRowsPerPage',
  reviewTablesCSVDownloads: 'reviewTablesCSVDownloads',
  reviewTablesInsightsCSVDownloads: 'reviewTablesInsightsCSVDownloads',
  reviewTablesManageCSVDownloads: 'reviewTablesManageCSVDownloads',
  rabbit1on1GroupByIndividualTableRowsPerPage: 'rabbit1on1GroupByIndividualTableRowsPerPage',
  rabbit1on1GroupByManagerTableRowsPerPage: 'rabbit1on1GroupByManagerTableRowsPerPage',
  rabbitInitiativeTableRowsPerPage: 'rabbitInitiativeTableRowsPerPage',
  rabbitInitiativeAnalyticsFilters: 'rabbitInitiativeAnalyticsFilters',
  rabbitEngagementTableSurveys: 'rabbitEngagementTableSurveys',
  lastNotifiedReviewTime: 'lastNotifiedReviewTime',
  demoUserEmail: 'demoUserEmail',
  /* download okr insights keys start */
  individualInsightsCSVDownload: 'individualInsightsCSVDownload',
  objectiveInsightsCSVDownload: 'objectiveInsightsCSVDownload',
  /* download okr insights keys end */
};
