
import { Icon } from '@chakra-ui/react';
export const SomewhatHelpful = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
<path d="M10.0622 4.21764C6.51407 5.11418 4.00049 8.34496 4.00049 11.9957C4.00049 13.07 4.24296 14.2492 4.65515 15.1296C4.73597 15.3234 4.80871 15.4931 4.80871 15.5173C4.80871 15.5334 4.97844 15.8323 5.18858 16.1796C5.62502 16.9065 6.87777 18.2069 7.55668 18.6431C9.0034 19.5719 10.4016 20 11.9858 20C15.8167 20 19.0901 17.3588 19.8902 13.6192C20.0438 12.8923 20.0357 10.8973 19.874 10.2673C19.8013 10.0007 19.7285 9.69381 19.7043 9.58073C19.6801 9.46766 19.5103 9.04766 19.3164 8.65188C18.2252 6.34995 16.3178 4.7911 13.8366 4.17725C12.8263 3.92687 11.121 3.94302 10.0622 4.21764Z" fill="currentColor" fill-opacity="0.16"/>
<path d="M10.1256 4.18734C6.83521 5.06081 4.66584 7.47091 4.07713 10.9001C3.83519 12.3397 4.17391 14.2564 4.93197 15.696C7.10134 19.8045 12.182 21.2279 16.2062 18.8421C16.9401 18.4054 18.0611 17.3378 18.5853 16.5695C19.1417 15.7688 19.5772 14.8064 19.803 13.9006C20.0208 13.0352 20.0692 11.1346 19.8917 10.3825C19.3675 8.16645 18.2063 6.46805 16.3998 5.27917C15.5691 4.72921 14.9885 4.47041 13.9804 4.20352C13.2949 4.0175 13.0369 3.99324 12.0046 4.00133C11.0369 4.00133 10.682 4.04177 10.1256 4.18734ZM13.6578 4.97184C16.2627 5.60268 18.2546 7.5437 18.9643 10.1398C19.1175 10.706 19.1498 11.0214 19.1498 11.9595C19.1498 13.8116 18.7063 15.1218 17.5933 16.5129C16.5046 17.8878 15.0207 18.745 13.1498 19.0847C11.3352 19.4082 9.35136 18.9311 7.82715 17.8069C6.44005 16.7798 5.51262 15.421 5.06101 13.7388C4.30294 10.9486 5.52875 7.71354 7.98845 6.01514C8.56103 5.61885 9.60942 5.15786 10.2868 5.00419C10.6175 4.9314 10.932 4.85861 10.9723 4.84244C11.0207 4.82626 11.4804 4.81009 12.0046 4.81009C12.7143 4.802 13.1336 4.84244 13.6578 4.97184Z" fill="currentColor"/>
<path d="M14.7143 9.44429C14.4159 9.5656 14.2465 9.72735 14.1014 10.0023C13.9965 10.2207 13.9965 10.2773 14.1175 10.6008C14.3514 11.2155 14.9078 11.4338 15.432 11.1103C16.182 10.6493 16.0127 9.66265 15.1337 9.42003C15.0207 9.37959 14.8272 9.39576 14.7143 9.44429Z" fill="currentColor"/>
<path d="M8.75458 9.46855C8.69006 9.49281 8.53684 9.60604 8.41587 9.71927C8.23038 9.88911 8.19006 9.99425 8.19006 10.3258C8.19006 10.6493 8.23038 10.7545 8.42393 10.9486C8.69006 11.2155 8.98039 11.3125 9.28684 11.2397C9.40781 11.2074 9.60136 11.078 9.73039 10.9486C9.92394 10.7545 9.96426 10.6493 9.96426 10.3178C9.96426 9.95381 9.93201 9.88911 9.682 9.66265C9.39974 9.42003 9.07716 9.34724 8.75458 9.46855Z" fill="currentColor"/>
<path d="M8.932 14.1189C8.70619 14.3454 8.8191 14.6689 9.29491 15.1299C10.4481 16.2621 12.2627 16.529 13.8111 15.8092C14.3514 15.5504 14.9885 15.0167 15.174 14.6446C15.2869 14.4424 15.2869 14.3939 15.1659 14.216C14.9724 13.9248 14.7304 13.9734 14.3191 14.3939C13.6256 15.1137 12.7062 15.4615 11.7385 15.3644C10.8433 15.2755 10.3272 15.0167 9.682 14.3535C9.36749 14.0219 9.10942 13.941 8.932 14.1189Z" fill="currentColor"/>
</Icon>
  );
