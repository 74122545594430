import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  isRecalibrate: false,
  // setIsRecalibrate,
  tableRowData: {},
  empId: null,
  // setTableRowData,
  isRecomputeLoading: false,
  // setIsRecomputeLoading,
  isCalibrationSidepanelOpen: false,
  // setIsCalibrationSidepanelOpen,
  tableResetCounter: 0,
  // setTableResetCounter,
  reviewCycleStage: null,
  gridRows: 3,
  // setGridRows,
  gridColumns: 3,
  // setGridColumns
  selectedReviewCycle: null,

  peerProvidersList: [],
  upwardProvidersList: [],
  dlmProvidersList: [],
};

export const reviewManageSidepanel = createSlice({
  name: 'reviewManageSidepanel',
  initialState,
  reducers: {
    setIsRecalibrate: (state, action) => {
      state.isRecalibrate = action.payload;
    },

    updateTableRowData: (state, action) => {
      state.tableRowData = {
        ...state.tableRowData,
        ...action.payload,
      };
    },
    setIsRecomputeLoading: (state, action) => {
      state.isRecomputeLoading = action.payload;
    },
    setIsCalibrationSidepanelOpen: (state, action) => {
      state.isCalibrationSidepanelOpen = action.payload;
    },
    incrementTableResetCounter: (state) => {
      state.tableResetCounter += 1;
    },
    setReviewCycleStage: (state, action) => {
      state.reviewCycleStage = action.payload;
    },
    setGridRows: (state, action) => {
      state.gridRows = action.payload;
    },
    setGridColumns: (state, action) => {
      state.gridColumns = action.payload;
    },
    setSelectedReviewCycle: (state, action) => {
      state.selectedReviewCycle = action.payload;
    },
    setEmpId: (state, action) => {
      state.empId = action.payload;
    },
    setPeerProvidersList: (state, action) => {
      state.peerProvidersList = action.payload;
    },
    setUpwardProvidersList: (state, action) => {
      state.upwardProvidersList = action.payload;
    },
    setDLMProvidersList: (state, action) => {
      state.dlmProvidersList = action.payload;
    },
    // keeping selected review cycle to persist selected review cycle among all review manage pages
    resetReviewManageSidepanelState: (state) => ({
      ...initialState,
      selectedReviewCycle: state.selectedReviewCycle,
    }),
  },
});

export const {
  setIsRecalibrate,
  updateTableRowData,
  setIsRecomputeLoading,
  setIsCalibrationSidepanelOpen,
  incrementTableResetCounter,
  setReviewCycleStage,
  setGridRows,
  setGridColumns,
  setSelectedReviewCycle,
  setEmpId,
  setPeerProvidersList,
  setUpwardProvidersList,
  setDLMProvidersList,
  resetReviewManageSidepanelState,
} = reviewManageSidepanel.actions;

export default reviewManageSidepanel.reducer;

// selectors
export const useReviewManageSidepanelData = () => useSelector((state) => state.reviewManageSidepanel);
