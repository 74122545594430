
import { Icon } from '@chakra-ui/react';
export const ExtremelyHelpful = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
<path d="M10.0622 4.21764C6.51407 5.11418 4.00049 8.34496 4.00049 11.9957C4.00049 13.07 4.24296 14.2492 4.65515 15.1296C4.73597 15.3234 4.80871 15.4931 4.80871 15.5173C4.80871 15.5334 4.97844 15.8323 5.18858 16.1796C5.62502 16.9065 6.87777 18.2069 7.55668 18.6431C9.0034 19.5719 10.4016 20 11.9858 20C15.8167 20 19.0901 17.3588 19.8902 13.6192C20.0438 12.8923 20.0357 10.8973 19.874 10.2673C19.8013 10.0007 19.7285 9.69381 19.7043 9.58073C19.6801 9.46766 19.5103 9.04766 19.3164 8.65188C18.2252 6.34995 16.3178 4.7911 13.8366 4.17725C12.8263 3.92687 11.121 3.94302 10.0622 4.21764Z" fill="currentColor" fill-opacity="0.16"/>
<path d="M10.0617 4.21764C6.51358 5.11418 4 8.34496 4 11.9957C4 13.07 4.24247 14.2492 4.65466 15.1296C4.73548 15.3234 4.80823 15.4931 4.80823 15.5173C4.80823 15.5334 4.97795 15.8323 5.18809 16.1796C5.62453 16.9065 6.87728 18.2069 7.55619 18.6431C9.00291 19.5719 10.4011 20 11.9853 20C15.8162 20 19.0896 17.3588 19.8897 13.6192C20.0433 12.8923 20.0352 10.8973 19.8735 10.2673C19.8008 10.0007 19.7281 9.69381 19.7038 9.58073C19.6796 9.46766 19.5098 9.04766 19.3159 8.65188C18.2248 6.34995 16.3173 4.7911 13.8361 4.17725C12.8258 3.92687 11.1205 3.94302 10.0617 4.21764ZM13.4158 4.93649C14.3938 5.16264 14.5393 5.2111 15.2909 5.5988C16.6972 6.30149 17.7964 7.41611 18.4672 8.81342C19.0249 9.9765 19.1946 10.7196 19.1946 11.9877C19.1865 14.015 18.5642 15.5334 17.1417 16.9954C16.0102 18.1665 14.8383 18.7723 13.1734 19.0712C12.1469 19.2569 11.6054 19.2246 10.0213 18.9015C9.63333 18.8208 8.51798 18.2796 7.95222 17.8919C5.68111 16.3654 4.42028 13.4092 4.92138 10.7842C5.49522 7.82804 7.62893 5.5988 10.5466 4.93649C11.1609 4.79918 12.8177 4.79918 13.4158 4.93649Z" fill="currentColor"/>
<path d="M8.93017 9.60497C8.54223 9.71804 8.1462 10.025 7.99263 10.3238C7.88756 10.5338 7.87948 10.6065 7.96839 10.76C8.11387 11.0427 8.40483 11.0023 8.77661 10.6711C8.9625 10.5096 9.18072 10.3884 9.29388 10.3884C9.40703 10.3884 9.62525 10.5096 9.81114 10.6711C10.191 11.0184 10.5143 11.0507 10.6274 10.7438C10.7729 10.3642 10.2718 9.75843 9.69799 9.6292C9.56867 9.59689 9.39895 9.56458 9.33429 9.54843C9.26963 9.53227 9.08374 9.5565 8.93017 9.60497Z" fill="currentColor"/>
<path d="M14.2887 9.6292C13.8927 9.77458 13.3754 10.3077 13.3754 10.5742C13.3754 11.0346 13.8523 11.083 14.2321 10.6711C14.4261 10.4611 14.5554 10.3884 14.7494 10.3884C14.9434 10.3884 15.0727 10.4611 15.2667 10.6711C15.6627 11.0992 16.1234 11.0346 16.1234 10.55C16.1234 10.2592 15.6384 9.78266 15.1697 9.6292C14.7332 9.47573 14.7252 9.47573 14.2887 9.6292Z" fill="currentColor"/>
<path d="M8.57455 13.4496C8.4614 13.5707 8.44523 13.6838 8.49372 14.0311C8.64729 15.3073 9.36661 16.3331 10.4658 16.8419C11.8559 17.4881 13.4805 17.2135 14.5312 16.1634C15.0403 15.6546 15.3313 15.1134 15.5253 14.2896C15.6546 13.7484 15.6546 13.6919 15.5253 13.5061L15.396 13.2961H12.0499C8.88167 13.2961 8.70386 13.3042 8.57455 13.4496ZM14.6524 14.2734C14.6928 14.4834 14.3695 15.1054 14.0139 15.5092C13.8361 15.7111 13.4886 15.9696 13.2299 16.0988C12.8258 16.2927 12.6803 16.3169 12.0014 16.3169C11.072 16.3169 10.6274 16.1392 10.0455 15.5415C9.68182 15.1619 9.2777 14.3461 9.38277 14.1765C9.40702 14.1281 10.4658 14.1119 12.0257 14.12C14.3695 14.1442 14.6282 14.1604 14.6524 14.2734Z" fill="currentColor"/>
</Icon>
  );
