import * as Sentry from '@sentry/react';
import { PostActions } from 'models/postFeedItemData';
import PropTypes from 'prop-types';

import { TASK_STATUS, TASK_TYPES_1, TAG_ID_TYPE, TASK_TYPES_2, SEARCH_TAG_TYPES } from 'constants/ProjectConstants';
import { getDateFromEpoch, generateKey, notNullOrUndefined } from 'constants/helper';
import theme from 'constants/theme';

import { getEmployeeObjectFromRes } from 'services/helpers/employee';
import { convertTextMacrosToEditorState } from 'services/helpers/post';

import ColorCodedDate from './common/ColorCodedDate';
import Employee from './employee';
import FeedReactions from './feedReactions';
import HashTag from './hashtag';
import OrgObjective from './orgObjective';
import Team from './team';

// TODO: Maybe have a model which doesn't have functions for list item views?
// TODO:  Refactor, this has become too big, it should be brokern down into small components
export default class Task1 {
  static EMPLOYEE_TYPES = { owner: 'OWNER', collaborator: 'COLLABORATOR' };

  constructor({
    id,
    uuid,
    from,
    to,
    toIds = [],
    text = '',
    macros,
    createdDate,
    updatedDate,
    dueDate,
    isComplete = false,
    completionDate,
    editorState,
    lastEditedBy,
    detailedStatus,
    status,
    description,
    teamId,
    teamName,
    taskType,
    recurrenceDetails,
    parentTaskId,
    subTaskStats,
    discussionCommentsCount,
    measure,
    actual,
    target,
    type,
    goalProgressStatus,
    visibilityType,
    startDate,
    toIdType,
    subObjective,
    reactions,
    actions,
    isOnBoarding,
    parentPosts,
    startingProgressValue,
    goalProgressLevel,
    goalClosureStatus,
    weightage,
    source,
    metricCalculationExpression,
    goalName,
    teams,
    lastCheckInTS,
    trackedByPlugin,
    metricId,
    checkInState = null,
    // colored date object properies these will deprecate
    coloredStartDate = {
      timeStamp: null,
      color: theme.palette.grey.dark,
      isCrossed: false,
      reason: '',
    },
    coloredDueDate = {
      timeStamp: null,
      color: theme.palette.grey.dark,
      isCrossed: false,
      reason: '',
    },
    active, // only being used in 1:1 checkin,
    measureType,
    showLevelUpPanel = false, // only being used in 1:1 / Checkin
  } = {}) {
    this.id = id;
    this.uuid = uuid;
    this.from = from;
    this.to = to;
    this.toIds = toIds;
    this.text = text;
    this.macros = macros;
    this.createdDate = createdDate;
    this.updatedDate = updatedDate;
    this.dueDate = dueDate;
    this.completionDate = completionDate;
    this.editorState = editorState;
    this.lastEditedBy = lastEditedBy;
    this.detailedStatus = detailedStatus;
    this.status = status;
    this.description = description;
    this.teamId = teamId;
    this.teamName = teamName;
    this.taskType = taskType;
    this.recurrenceDetails = recurrenceDetails;
    this.parentTaskId = parentTaskId;
    this.subTaskStats = subTaskStats;
    this.discussionCommentsCount = discussionCommentsCount;
    this.measure = measure;
    this.actual = actual ?? 0;
    this.target = target;
    this.type = type;
    this.goalProgressStatus = goalProgressStatus;
    this.visibilityType = visibilityType;
    this.startDate = startDate;
    this.toIdType = toIdType;
    this.subObjective = subObjective;
    this.reactions = reactions || {};
    this.actions = actions;
    this.parentPosts = parentPosts;
    this.startingProgressValue = startingProgressValue || 0;
    this.goalProgressLevel = goalProgressLevel;
    this.goalClosureStatus = goalClosureStatus;
    this.weightage = weightage || 0;
    this.teams = teams;
    if (isOnBoarding !== undefined) this.isOnBoarding = isOnBoarding;
    this.source = source;
    this.metricCalculationExpression = metricCalculationExpression;
    this.goalName = goalName;
    this.lastCheckInTS = lastCheckInTS;
    this.trackedByPlugin = trackedByPlugin;
    this.metricId = metricId;
    this.checkInState = checkInState;
    this.coloredDueDate = coloredDueDate;
    this.coloredStartDate = coloredStartDate;
    this.active = active;
    this.measureType = measureType;
    this.showLevelUpPanel = showLevelUpPanel;
  }

  isComplete = () => !(this.completionDate === null || this.completionDate === undefined);

  isDelayed = () => {
    if (this.dueDate && !this.completionDate) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      return this.dueDate.getTime() <= yesterday.getTime();
    }
    return false;
  };

  getTaskStatus = () => {
    if (this.status === 'COMPLETED') {
      return TASK_STATUS.completed.value;
    }
    if (!this.dueDate) {
      return TASK_STATUS.unplanned.value;
    }
    return this.detailedStatus;
  };

  isRecurrent = () =>
    this.taskType === TASK_TYPES_1.recurrentTask || this.taskType === TASK_TYPES_1.recurrentInstanceTask;

  getFirstTag = () => {
    const { macros } = this;
    if (macros && macros.attributes && macros.attributes.orgTag) {
      const keys = Object.keys(macros.attributes.orgTag);
      if (keys[0] && macros.attributes.orgTag[keys[0]]) {
        return macros.attributes.orgTag[keys[0]];
      }
      return null;
    }
    return null;
  };

  addBasicTaskDetails(json) {
    this.id = json.uuid;
    this.text = json.text;
    this.macros = json.macros;
    this.type = json.type;
    return this;
  }

  addToIds(json) {
    const toOnlyIdsObject = {};
    const toIds = [];
    json.toIds = json.toIds || [];
    json.toIds.forEach((employee) => {
      if (!toOnlyIdsObject[employee.uuid]) {
        toOnlyIdsObject[employee.uuid] = true;
        toIds.push(Employee.getEmployeeObjectFromRes(employee));
      }
    });
    this.toIds = toIds;
    return this;
  }

  addParentPosts(json) {
    const result = {};
    // parentPosts is like this {goal: {uuid: 1}}, there can be other keys there as well, so making sure we only get uuid
    // in case later we update this task
    try {
      if (json && json.parentPosts) {
        Object.keys(json.parentPosts).forEach((postType) => {
          const postTypeData = json.parentPosts[postType];
          const newPostTypeData = postTypeData.map((post) => new Task1().addBasicTaskDetails(post).addToIds(post));
          result[postType] = newPostTypeData;
        });
      }
    } catch (err) {}
    this.parentPosts = result;
    return this;
  }

  /* 
    x = ( ( A - S ) / ( T - S ) )* 100
    x is the percentage change.
    A is actual or achieved
    S is the starting value
    T is the target value
  */
  /*
    shift functions like this to helpers/goal.js
    and make sure to make it a pure function
  */
  getGoalProgress = (round = 2, def) => {
    const startValue = this.startingProgressValue || 0;
    const achievedValue = this.actual;
    const targetValue = this.target;

    // in case startValue and targetValue is not defined Or are equal 0 progress.
    if (startValue === targetValue) return 0;

    if (notNullOrUndefined(targetValue) && notNullOrUndefined(achievedValue)) {
      return Number(((achievedValue - startValue) / (targetValue - startValue)) * 100).toFixed(round);
    }

    return def || 0;
  };

  static fromJSON(json) {
    const taskObject = new Task1();
    taskObject.addToIds(json).addParentPosts(json);

    json.teams = json.teams || [];
    let teamId;
    let teamName;
    // If teams are more than 1, then we need to show default in the display
    if (json.teams.length > 1) {
      teamId = -2;
      teamName = 'Default';
    } else if (json.teams.length > 0) {
      teamId = json.teams[0].uuid;
      teamName = json.teams[0].name;
    } else {
      teamId = null;
      teamName = '';
    }
    if (!json.toIdType) {
      json.toIdType = {};
      Object.values(TAG_ID_TYPE).forEach((idType) => {
        json.toIdType[idType] = [];
      });
    }
    let subObjective;

    /*
      with support of timebound objectives we are making following decisions.

      It is likely that some old tags ( inactive tags ) won't have timebound info along with them.
      To make older goals/tasks work , we are handling things in following fashion

      - if there is goal tag type 
        - if that tag type has objective time bound info, read that
        - else read the tag in old way. ( this will result in undefined objective Level but things should work in general )
      
      - if there is subobjective tag , that will take priority over goal tag.
        - if that tag type has objective time bound info, read that
        - else read the tag in old way. ( this will result in undefined objective Level but things should work in general )
      
    */
    if (json.tags && json.tags[SEARCH_TAG_TYPES.goal] && json.tags[SEARCH_TAG_TYPES.goal].length > 0) {
      if (json.tags[SEARCH_TAG_TYPES.goal][0]?.objectiveTimeBound) {
        subObjective = OrgObjective.fromJSON(json.tags[SEARCH_TAG_TYPES.goal][0]?.objectiveTimeBound);
      } else {
        subObjective = new HashTag();
        subObjective.fromJSON(json.tags[SEARCH_TAG_TYPES.goal][0]);
      }
    }

    if (
      json.tags &&
      json.tags[SEARCH_TAG_TYPES.subObjectiveTag] &&
      json.tags[SEARCH_TAG_TYPES.subObjectiveTag].length > 0
    ) {
      if (json.tags[SEARCH_TAG_TYPES.subObjectiveTag][0]?.objectiveTimeBound) {
        subObjective = OrgObjective.fromJSON(json.tags[SEARCH_TAG_TYPES.subObjectiveTag][0]?.objectiveTimeBound);
      } else {
        subObjective = new HashTag();
        subObjective.fromJSON(json.tags[SEARCH_TAG_TYPES.subObjectiveTag][0]);
      }
    }

    const reactions = new FeedReactions();
    reactions.fromJSON(json.reactions || {});
    const actions = PostActions.fromJSON({
      actionsJSON: json.actions,
      type: 'TASK',
      isContentStatePresent: true,
    });

    if (json.actual === null && json.type === TASK_TYPES_2.goal) {
      Sentry.withScope((scope) => {
        scope.setLevel('fatal');
        scope.setTag('severity', 1);

        const error = new Error('');
        error.name = '[SEV1]: Null in actual value of goal';
        error.message = `null in actual value of goal for goal id : ${json.uuid}`;

        const eventId = Sentry.captureException(error);
      });
    }

    return new Task1({
      ...taskObject,
      id: json.uuid,
      uuid: json.uuid,
      from: json.from != null ? Employee.getEmployeeObjectFromRes(json.from) : null,
      to: json.to != null ? Employee.getEmployeeObjectFromRes(json.to) : null,
      text: json.text,
      macros: json.macros,
      createdDate: getDateFromEpoch(json.createdTS),
      updatedDate: getDateFromEpoch(json.updatedTS),
      // completionDate: json.completionTS && getDateFromEpoch(json.completionTS),
      completionDate: json.completionTS && getDateFromEpoch(json.completionTS),
      // dueDate: json.dueTS && getDateFromEpoch(json.dueTS),
      dueDate: json.dueTS && getDateFromEpoch(json.dueTS),
      coloredDueDate: json.dueTime && ColorCodedDate.fromJSON(json.dueTime),
      startDate: json.startTS && getDateFromEpoch(json.startTS),
      coloredStartDate: json.startTime && ColorCodedDate.fromJSON(json.startTime),
      // editorState: getEditorStateFromStringifiedRawState({
      //   stringifiedRawState: json.contentState
      // }),
      editorState: convertTextMacrosToEditorState(json.text, json.macros),
      lastEditedBy: json.lastEditedBy ? getEmployeeObjectFromRes(json.lastEditedBy) : null,
      detailedStatus: json.detailedStatus,
      status: json.status,
      description: json.description,
      teamId,
      teamName,
      teams: json.teams ? json.teams.map((json) => Team.fromJSON1(json)) : [],
      taskType: json.taskType,
      recurrenceDetails: json.recurrenceDetails,
      parentTaskId: json.parentPost ? json.parentPost.uuid : null,
      subTaskStats: json.subTaskStats,
      discussionCommentsCount: json.discussionCommentsCount ? json.discussionCommentsCount : 0,
      measure: json.measure,
      actual: json.actual ?? 0,
      target: json.target,
      startingProgressValue: json.startingProgressValue,
      type: json.type,
      goalProgressStatus: json.goalProgressStatus,
      visibilityType: json.visibilityType,
      toIdType: json.toIdType,
      subObjective,
      reactions,
      actions,
      isOnBoarding: json.isOnBoarding,
      goalProgressLevel: json.goalProgressLevel,
      goalClosureStatus: json.goalClosureStatus,
      weightage: json.weightage,
      source: json.source,
      metricCalculationExpression: json.metricCalculationExpression,
      goalName: json.goalName,
      lastCheckInTS: json.lastCheckInTS ? getDateFromEpoch(json.lastCheckInTS) : null,
      trackedByPlugin: json.trackedByPlugin,
      metricId: json.metricId,
      checkInState: json.checkInState,
      active: json.active,
      measureType: json.measureType,
      showLevelUpPanel: json.showLevelUpPanel,
    });
  }

  // Using this function because in case of multiple owners, we would like to
  // show comma separated names
  getOwnersDisplayNames = () => this.toIds.map((employee) => employee.displayName).join(', ');

  isUnassignedTask = () =>
    // todo: can remove the condition - this.toIds[0].isUnassignedUser(), after support from backend
    !this.toIds || !this.toIds.length || this.toIds[0].isUnassignedUser();

  getStatusColor = (defaultColor = 'textSecondary') => {
    if (!this.detailedStatus) return 'textSecondary';
    switch (this.detailedStatus) {
      case 'DELAYED':
        return 'red';
      default:
        return defaultColor;
    }
  };

  // todo: to use utils.getGoalProgressColor
  getGoalProgressColor = (defaultColor = 'textSecondary') => {
    if (!this.goalProgressStatus) return 'textSecondary';
    if (this.goalProgressStatus === 'DELAYED') {
      return 'red';
    }
    if (this.goalProgressStatus === 'ONGOING') {
      return 'orange';
    }
    if (this.goalProgressStatus.indexOf('COMPLETED') > -1) {
      return 'green';
    }
  };

  getCompletedColor = (defaultColor = 'textPrimary') => {
    if (!this.detailedStatus) return 'textPrimary';
    switch (this.detailedStatus) {
      case 'COMPLETED_WITH_DELAY':
        return 'red';
      default:
        return defaultColor;
    }
  };

  static generateUniqueKey() {
    const keyLength8 = generateKey(8);
    const timestamp = new Date().getTime();
    return `${keyLength8}${timestamp}`;
  }
}

Task1.propTypes = {
  id: PropTypes.string,
  from: PropTypes.objectOf(Employee),
  to: PropTypes.objectOf(Employee),
  text: PropTypes.string,
  macros: PropTypes.object,
  createdDate: PropTypes.objectOf(Date),
  updatedDate: PropTypes.objectOf(Date),
  dueDate: PropTypes.objectOf(Date),
  completionDate: PropTypes.objectOf(Date),
  taskType: PropTypes.string,
  recurrenceDetails: PropTypes.object,
};
