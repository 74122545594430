import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntentValuesType } from 'Deer/intent/types/IntentCommonTypes';
import { useSelector } from 'react-redux';

import { past3Months } from 'constants/ProjectConstants';

export type EngagementDateRange = { label?: string; startDate: Date; endDate: Date };

export type EngagementIntentType = {
  label: string;
  onClick: () => void;
  visible: () => boolean;
  value: {
    myData?: IntentValuesType;
  };
  queryParamValue?: string;
  category: 'Manage' | 'Analyze' | 'Configure';
  showTimelineDropdown?: boolean;
  showSurveyDropdown?: boolean;
  showSplitByDropdown?: boolean;
  isDefault?: boolean;
};

const initialState: {
  selectedIntent?: EngagementIntentType;
  splitByOptions?: {
    uuid?: string;
    keyName?: string;
    displayName?: string;
    enabled?: boolean;
  }[];
  selectedDateRange?: EngagementDateRange;
  selectedSplitBy?: {
    value: string;
    label: string;
    uuid: string;
  };
  surveys?: {
    uuid?: string;
    keyName?: string;
    displayName?: string;
    enabled?: boolean;
    performanceEvaluationGrid?: unknown | null;
    reviewType?: string;
    feedbackForms?: unknown | null;
  }[];
} = {
  selectedIntent: null,
  splitByOptions: [
    {
      uuid: null,
      keyName: 'none',
      displayName: 'None',
      enabled: true,
    },
    {
      uuid: null,
      keyName: 'function',
      displayName: 'Function',
      enabled: true,
    },
    {
      uuid: null,
      keyName: 'location',
      displayName: 'Location',
      enabled: true,
    },
    {
      uuid: null,
      keyName: 'designations',
      displayName: 'Designation',
      enabled: true,
    },
  ],
  selectedDateRange: past3Months,
  selectedSplitBy: {
    value: 'none',
    label: 'None',
    uuid: null,
  },
  surveys: null,
};

export const engagementIntentSlice = createSlice({
  name: 'engagementIntent',
  initialState,
  reducers: {
    setSelectedEngagementIntent: (state, action: PayloadAction<EngagementIntentType>) => {
      const newState = { ...state };
      newState.selectedIntent = action.payload;
      return newState;
    },
    setSelectedSplitByEngagementIntent: (
      state,
      action: PayloadAction<{ value: string; label: string; uuid: string }>
    ) => {
      state.selectedSplitBy = action.payload;
    },
    setSelectedDateRangeEngagementIntent: (state, action: PayloadAction<EngagementDateRange>) => {
      state.selectedDateRange = action.payload;
    },
    setEngagementSurveysEngagementIntent: (
      state,
      action: PayloadAction<{ surveys: (typeof initialState)['surveys'] }>
    ) => {
      state.surveys = action.payload.surveys;
    },
  },
});

export const {
  setSelectedEngagementIntent,
  setSelectedSplitByEngagementIntent,
  setSelectedDateRangeEngagementIntent,
  setEngagementSurveysEngagementIntent,
} = engagementIntentSlice.actions;

export default engagementIntentSlice.reducer;

export const useSelectEngagementSelectedIntent = () =>
  useSelector((state: { engagementIntent: typeof initialState }) => state.engagementIntent.selectedIntent);

export const useSelectEngagementSplitByOptions = () =>
  useSelector((state: { engagementIntent: typeof initialState }) => state.engagementIntent.splitByOptions);

export const useSelectEngagementSelectedSplitBy = () =>
  useSelector((state: { engagementIntent: typeof initialState }) => state.engagementIntent.selectedSplitBy);

export const useSelectEngagementSelectedDateRange = () =>
  useSelector((state: { engagementIntent: typeof initialState }) => state.engagementIntent.selectedDateRange);

export const useSelectEngagementSurveys = () =>
  useSelector((state: { engagementIntent: typeof initialState }) => state.engagementIntent.surveys);
