
import { Icon } from '@chakra-ui/react';
export const ExtremelyUnhelpful = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
<path d="M10.0617 4.21764C6.51358 5.11418 4 8.34496 4 11.9957C4 13.07 4.24247 14.2492 4.65466 15.1296C4.73548 15.3234 4.80823 15.4931 4.80823 15.5173C4.80823 15.5334 4.97795 15.8323 5.18809 16.1796C5.62453 16.9065 6.87728 18.2069 7.55619 18.6431C9.00291 19.5719 10.4011 20 11.9853 20C15.8163 20 19.0896 17.3588 19.8897 13.6192C20.0433 12.8923 20.0352 10.8973 19.8735 10.2673C19.8008 10.0007 19.7281 9.69381 19.7038 9.58073C19.6796 9.46766 19.5098 9.04766 19.3159 8.65188C18.2248 6.34995 16.3174 4.7911 13.8361 4.17725C12.8258 3.92687 11.1205 3.94302 10.0617 4.21764Z" fill="currentColor" fill-opacity="0.16"/>
<path d="M9.86634 4.28542C8.6822 4.61173 7.49805 5.30273 6.43038 6.28164C2.78088 9.67905 3.32443 15.8021 7.49805 18.5661C11.7493 21.3876 17.2042 19.9097 19.4754 15.3222C19.9607 14.3625 19.9995 14.0746 19.9995 12.0592C19.9995 9.98616 19.9607 9.77502 19.3783 8.56578C17.6895 5.03401 13.7488 3.26812 9.86634 4.28542ZM15.2047 5.53306C16.7188 6.22406 17.9224 7.41411 18.6795 8.94966C19.1648 9.92858 19.223 10.2357 19.223 12.0592C19.223 14.0362 19.2036 14.0938 18.5048 15.3414C17.6312 16.8578 16.5636 17.8751 15.0688 18.5661C14.1176 19.0075 13.7294 19.0651 12.0405 19.0651C10.3128 19.0651 9.98281 19.0075 9.0122 18.5469C7.53688 17.8175 6.17802 16.4547 5.45977 14.9383C4.93564 13.8442 4.85799 13.4795 4.85799 12.0592C4.85799 10.0054 5.38212 8.62336 6.70215 7.20297C8.93455 4.78448 12.1958 4.13187 15.2047 5.53306Z" fill="currentColor"/>
<path d="M7.5757 9.54469C7.43981 9.77502 7.94453 10.2741 8.44925 10.4276C9.0122 10.6004 8.93455 10.7923 8.15807 11.157C7.4204 11.5025 7.28452 11.8096 7.78923 12.0016C8.2163 12.1551 10.3905 11.0994 10.3905 10.7155C10.3905 10.5428 9.9634 10.1973 9.3228 9.89019C8.17748 9.33355 7.76982 9.25678 7.5757 9.54469Z" fill="currentColor"/>
<path d="M14.8553 9.92858C14.2729 10.2165 13.7876 10.5812 13.7876 10.7155C13.7876 11.0035 15.7094 12.04 16.2724 12.0592C16.8547 12.0592 16.6218 11.5025 15.8841 11.1186L15.1465 10.7539L15.8841 10.3317C16.6412 9.89019 16.8547 9.37194 16.2724 9.37194C16.0782 9.37194 15.4376 9.62147 14.8553 9.92858Z" fill="currentColor"/>
<path d="M11.3805 14.8807C11.0117 15.1303 10.8369 15.1495 10.5652 14.9767C10.0216 14.6312 9.3228 14.708 8.93455 15.1303C8.66278 15.4374 8.62396 15.5717 8.79867 15.7445C8.99279 15.9364 9.12868 15.9364 9.43927 15.7445C9.78869 15.5333 9.90516 15.5333 10.1769 15.7829C10.6428 16.2051 11.1475 16.1668 11.6523 15.7061L12.0793 15.3222L12.4288 15.7061C12.8558 16.1668 13.6323 16.2051 14.1176 15.7637C14.4476 15.4758 14.4864 15.4758 14.8359 15.7829C15.1465 16.0516 15.2241 16.0708 15.4182 15.8213C15.6124 15.5909 15.5735 15.4566 15.2629 15.1495C14.7776 14.6696 14.137 14.6312 13.6905 15.0343C13.3994 15.303 13.3217 15.2838 12.9141 14.9383C12.3705 14.4777 11.9823 14.4585 11.3805 14.8807Z" fill="currentColor"/>
</Icon>
  );
