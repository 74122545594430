
import { Icon } from '@chakra-ui/react';
export const SomewhatUnhelpful = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
<path d="M10.0617 4.21764C6.51358 5.11418 4 8.34496 4 11.9957C4 13.07 4.24247 14.2492 4.65466 15.1296C4.73548 15.3234 4.80823 15.4931 4.80823 15.5173C4.80823 15.5334 4.97795 15.8323 5.18809 16.1796C5.62453 16.9065 6.87728 18.2069 7.55619 18.6431C9.00291 19.5719 10.4011 20 11.9853 20C15.8163 20 19.0896 17.3588 19.8897 13.6192C20.0433 12.8923 20.0352 10.8973 19.8735 10.2673C19.8008 10.0007 19.7281 9.69381 19.7038 9.58073C19.6796 9.46766 19.5098 9.04766 19.3159 8.65188C18.2248 6.34995 16.3174 4.7911 13.8361 4.17725C12.8258 3.92687 11.1205 3.94302 10.0617 4.21764Z" fill="currentColor" fill-opacity="0.16"/>
<path d="M10.9717 4.01688C8.29996 4.42867 6.14484 5.92245 4.89374 8.24789C4.29644 9.35408 4.08658 10.1615 4.02201 11.5584C3.97358 12.5758 3.98972 12.8019 4.20766 14.0211C4.27223 14.3602 4.81303 15.5956 5.13589 16.1366C5.5314 16.7987 6.21749 17.59 6.8955 18.1633C7.56544 18.7204 8.96183 19.4633 9.76092 19.6813C11.6497 20.19 13.264 20.0931 15.064 19.3664C16.6702 18.7204 18.1877 17.3559 19.019 15.8379C19.9554 14.118 20.2379 12.0348 19.7939 10.1858C19.3016 8.11062 17.9697 6.23735 16.2263 5.1796C14.9106 4.38023 13.8129 4.05725 12.2712 4.0088C11.69 3.99265 11.1089 4.00073 10.9717 4.01688ZM13.5142 4.95351C15.855 5.50257 17.7599 7.10939 18.6478 9.29756C19.0513 10.2826 19.1159 10.6379 19.1159 11.9218C19.124 13.3832 18.9787 14.0211 18.3814 15.2404C17.5904 16.8472 15.8065 18.349 14.1599 18.8254C13.9582 18.8819 13.6918 18.9627 13.5546 19.003C13.151 19.1322 11.5044 19.1726 10.8667 19.0757C10.5439 19.0273 10.0111 18.8981 9.68827 18.785C7.67845 18.1068 5.94305 16.4192 5.22468 14.4329C4.90181 13.5447 4.85338 13.2056 4.84531 12.0025C4.84531 10.9932 4.8776 10.6864 5.02289 10.1615C5.77355 7.50504 7.75109 5.60754 10.447 4.94544C10.6488 4.89699 10.9717 4.84047 11.1734 4.82432C11.7062 4.77587 13.0945 4.85662 13.5142 4.95351Z" fill="currentColor"/>
<path d="M8.57439 9.55594C8.05781 9.8547 7.94481 10.4441 8.33225 10.9044C8.61475 11.2516 9.13941 11.3323 9.52684 11.082C10.0757 10.7429 10.0676 9.87892 9.52684 9.54787C9.18784 9.33793 8.95376 9.33793 8.57439 9.55594Z" fill="currentColor"/>
<path d="M14.5232 9.52365C14.1922 9.72551 14.1034 9.8547 14.0469 10.1858C13.9824 10.533 14.1922 10.9851 14.4828 11.1385C14.6039 11.1951 14.838 11.2274 15.0478 11.2031C15.9518 11.1143 16.1698 9.90315 15.3384 9.5075C14.9832 9.33793 14.8218 9.34601 14.5232 9.52365Z" fill="currentColor"/>
<path d="M10.9636 14.0534C10.3582 14.2714 10.108 14.4168 9.7367 14.7801C9.33312 15.1758 9.23626 15.4584 9.43805 15.6764C9.64792 15.9105 9.81742 15.8782 10.1322 15.5472C11.1492 14.4814 12.6506 14.4491 13.7402 15.4664C14.1761 15.8782 14.3052 15.9105 14.5232 15.6764C14.725 15.4584 14.6281 15.1758 14.2245 14.7801C13.4093 13.9888 12.021 13.682 10.9636 14.0534Z" fill="currentColor"/>
</Icon>
  );
